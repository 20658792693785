<template>
  <div class="container">
    <div class="next__steps box">
      <div class="next__steps__title">
        <h1>Big bang next steps</h1>
      </div>

      <div class="next__steps__description">
        <p>
          Below is a description of the go-forward roadmap for The Big Bang
          mint. Please note that the
          <a href="https://discord.com/invite/7k2dNMUAe3" target="blank"
            >Emergent Properties Discord server</a
          >
          is the best place to find more detailed information and to ask
          questions of our team.
        </p>
      </div>

      <article class="next__steps__body">
        <ul>
          <li>
            <h4>1. CLOSING THE MINT</h4>
            <p>
              The Big Bang public mint, which launched on January 2nd, will
              close on Thursday January 27th at 5pm ET / 10pm UTC. After this
              point, the only way to collect a Big Bang will be to purchase one
              on the
              <a
                href="https://stashh.io/collection/secret14j66mdlnp2v5w7wutcnsa4ddmplsyk2uav2fnl"
                target="blank"
                >secondary market via Stashh.</a
              >
            </p>
          </li>
          <li>
            <h4>2. REWARDING COLLECTORS / FREE BIG BANGS</h4>
            <p>
              To thank all the Big Bang collectors who joined the Emergent
              Properties project in its early days, we have decided to issue
              free Big Bang mints in a 1-for-1 giveaway. After the mint closes
              on January 27th, all collectors will be able to mint one free Big
              Bang token for every Big Bang token they own. Think of this as a
              100% dividend issued to reward and incentivize collectors. <br />
              <br />

              We will open the 1-for-1 free mint program shortly after the
              public mint closes. This is scheduled for Friday
              January 28 at 2pm ET / 7pm UTC. Collectors will be able to come to
              EmProps.io and use their existing Big Bang token(s) to verify
              eligibility for free Big Bang mints. For example, if you own five
              Big Bangs you will be eligible to mint five more Big Bangs for
              free.<br />
              <br />

              If you're still planning to collect a Big Bang, or planning to add
              to your collection, now's the time to do it. Any tokens you
              collect between now and the mint closing on January 27th will be
              eligible for an additional free Big Bang.<br />
              <br />

              This is the first instance of Big Bang collectors being granted
              VIP access and special treatment. As we stated on The Big Bang
              project page: "Collectors of Big Bang NFTs will always have
              special status within the Emergent Properties community as
              supporters of our genesis collection." Big Bang collectors can
              look forward to more utility and value in the future, as we build
              Emergent Properties into the premiere ecosystem for generative
              NFTs.<br />
            </p>
          </li>
          <li>
            <h4>3. DEADSTOCK SUPPLY</h4>
            <p>
              Once the mint is closed, we will have a final count of minted Big
              Bangs. We'll take that number and double it to account for the
              free mints available to collectors, and any tokens remaining from
              the total 1024 supply will become the "Deadstock Supply." For
              example, if 400 Big Bangs have been sold at the time of closing,
              then 224 tokens will become the Deadstock Supply (1024 - 400 x
              2).<br /><br />

              These tokens will be minted by Emergent Properties and held in the
              Un-Generated State, to be used for marketing, rewards, and
              possibly for charitable giving in the future. The Deadstock Supply
              tokens will not be listed on Stashh and will not affect rarity
              calculations until the point where they are distributed and
              generated by their new owners. For transparency purposes, we will
              keep a running tally of the Deadstock Supply on EmProps.io.
            </p>
          </li>
        </ul>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "big-bang-nex-steps",
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 720px;
  margin: 42px auto;
  @include respond-to("small and down") {
    margin: 0px;
  }
}
.box {
  padding: 16px;
}
.next__steps {
  display: grid;
  row-gap: 26px;
  h1 {
    text-transform: capitalize;
    margin: 0px;
    font-size: 38px;
    line-height: 46px;
    font-style: italic;
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }
  p {
    color: var(--color-neutral-02);
    margin: 0px;
    line-height: 24px;
  }
  a {
    color: var(--color-emerald);
  }
  ul {
    margin: 0px;
  }
  li {
    margin-bottom: 26px;
    &:last-child {
      margin: 0px;
    }
  }
}
</style>
